
.styled-table {
    border-collapse: collapse;
    margin-bottom: 20px;
    width: 100%;
    background: #ffffff;
  }
  
  
  .styled-table th, .styled-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
  }
  
  .styled-table th {
    color: white;
    background-color: black;
  }